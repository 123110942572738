import { createSlice } from '@reduxjs/toolkit';
import { initialPageState } from './types';
import { IPageState } from './types';

const pageSlice = createSlice({
    name: 'page',
    initialState: initialPageState,
    reducers: {
        setTopic: (state: IPageState, action) => {
            state.topic = {
                id: action.payload.id,
                name: action.payload.name,
                categoryId: 0,
                heads: [],
            };
        },
        setHead: (state: IPageState, action) => {
            state.head = {
                id: action.payload.id,
                name: action.payload.name,
            };
        },
    },
});

export const { setTopic, setHead } = pageSlice.actions;

export default pageSlice.reducer;
