import { Route, Routes } from 'react-router-dom';
import { Header } from './components/Header/Header';
import { LandingPage } from './pages/LandingPage/LandingPage';
import { ProductPage } from './pages/ProductPage/ProductPage';
import styled, { ThemeProvider, css } from 'styled-components';
import { PageNotFound } from './pages/PageNotFound/PageNotFound';
import { BusinessList } from './pages/BusinessList/BusinessList';
import { ROUTE_NAMES } from './constants/routes';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from './store';

import {
    setBannersList,
    setCompaniesOfTheWeek,
    setScreenViewPort,
    switchBannerPositions,
    setRecommendedCompanies,
} from './store/global.slice';

import { getCategoriesList } from './services/categories.service';
import { setSearchFilters } from './store/companies/companies.slice';
import { setCategoriesList } from './store/categories/categories.slice';
import { getBanners } from './services/banners.service';
import { theme } from './assets/styles/theme';
import { MainPageLayout } from './pages/MainPageLayout/MainPageLayout';
import { getCompaniesOfTheWeek, getSearchFilters, getRecommendedCompanies } from './services/company.service';
import { AllCategories } from './pages/AllCategories/AllCategories';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { EuProjectsPage } from './pages/EuProjectsPage/EuProjectsPage';
import { getUserInformation } from './services/auth.service';
import { setUser } from './store/user/user.slice';
import { InnerCompanyPage } from './pages/InnerCompanyPage/InnerCompanyPage';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import storage from './commons/localStorage';
import { SSOLogin } from './components/SSOLogin/SSOLogin';
import CircularProgress from '@mui/material/CircularProgress';
// @ts-ignore
import { Helmet } from 'react-helmet';

const AppWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const LoadingModal = styled.div`
    ${(props) => css`
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100% + 150px);
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 100;
        transform: translate(0px, -119px);
    `}
`;

export const App = () => {
    const dispatch = useDispatch();
    const { screenViewPort, bannersList, loading } = useSelector((state: IState) => state.global);

    useEffect(() => {
        getCompaniesOfTheWeek().then((res) => dispatch(setCompaniesOfTheWeek(res)));
        getRecommendedCompanies().then((res) => dispatch(setRecommendedCompanies(res)));
        getUserInformation().then(
            (res) => {
                dispatch(setUser(res.data));
            },
            () => {
                storage.removeTokenData();
                dispatch(setUser(null));
            }
        );
        getSearchFilters().then((res) => {
            dispatch(setSearchFilters(res));
        });
        getBanners().then((response) => {
            dispatch(setBannersList(response));
        });
        getCategoriesList().then((response) => {
            dispatch(setCategoriesList(response));
        });
        calculateViewPort();
        window.addEventListener('resize', calculateViewPort);
        return () => {
            window.removeEventListener('resize', calculateViewPort);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const bannerSwitchInterval = setInterval(() => {
            dispatch(switchBannerPositions());
        }, 10000);
        return () => {
            clearInterval(bannerSwitchInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersList]);

    const calculateViewPort = () => {
        // outerWidth doens work when you open a header link in a new window. InnerWidth solves this problem.
        const windowWidth: number = window.innerWidth;
        if (windowWidth >= 768 && windowWidth < 1024) {
            dispatch(setScreenViewPort('tablet'));
            return;
        }
        if (windowWidth < 768) {
            dispatch(setScreenViewPort('mobile'));
            return;
        }
        if (windowWidth >= 1024) {
            dispatch(setScreenViewPort('desktop'));
        }
    };
    return (
        <ThemeProvider theme={{ ...theme, screen: { [screenViewPort]: true } }}>
            <Helmet>
                <title>Imonės.lt</title>
            </Helmet>
            <AppWrapper>
                {loading && (
                    <LoadingModal>
                        <CircularProgress sx={{ color: `rgb(221, 0, 20)`, zIndex: 1000 }} />
                    </LoadingModal>
                )}
                <ScrollToTop />
                <Header />
                <Routes>
                    <Route path="/linkedin" element={<LinkedInCallback />} />
                    <Route element={<MainPageLayout />}>
                        <Route path="/odoo" element={<SSOLogin />} />
                        <Route path={ROUTE_NAMES.HOME} element={<LandingPage />} />
                        <Route path={ROUTE_NAMES.PRODUCTS} element={<ProductPage />} />
                        <Route path={ROUTE_NAMES.COMPANY_CARD} element={<InnerCompanyPage />} />
                        <Route path={ROUTE_NAMES.EU_PROJECTS} element={<EuProjectsPage />} />
                        <Route path={ROUTE_NAMES.SEARCH}>
                            <Route index element={<BusinessList />} />
                        </Route>
                        <Route path={ROUTE_NAMES.BUSINESS_LIST}>
                            <Route index element={<AllCategories />} />
                            <Route path={ROUTE_NAMES.BUSINESS_LIST_CATEGORY}>
                                <Route index element={<AllCategories />} />
                                <Route path={ROUTE_NAMES.BUSINESS_LIST_TOPIC}>
                                    <Route index element={<BusinessList />} />
                                    <Route path={ROUTE_NAMES.BUSINESS_LIST_HEAD}>
                                        <Route index element={<BusinessList />} />
                                        <Route path={ROUTE_NAMES.BUSINESS_LIST_CITY}>
                                            <Route index element={<BusinessList />} />
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                        <Route path={ROUTE_NAMES.FULL_BUSINESS_LIST}>
                            <Route index element={<AllCategories />} />
                            <Route path={ROUTE_NAMES.BUSINESS_LIST_CATEGORY}>
                                <Route index element={<AllCategories />} />
                                <Route path={ROUTE_NAMES.BUSINESS_LIST_TOPIC}>
                                    <Route index element={<BusinessList />} />
                                    <Route path={ROUTE_NAMES.BUSINESS_LIST_HEAD}>
                                        <Route index element={<BusinessList />} />
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                        <Route path={ROUTE_NAMES.EU_PROJECTS} element={<EuProjectsPage />} />
                    </Route>
                    <Route path={ROUTE_NAMES.PAGE_NOT_FOUND} element={<PageNotFound />} />
                </Routes>
            </AppWrapper>
        </ThemeProvider>
    );
};
