import { CategoriesResponse, TopicResponse, HeadResponse } from '../types/api';
import http from './client/http';

export const getCategoriesList = () =>
    http.get<CategoriesResponse>(`/categories?withHeads=true`).then((res) => res.data.data);

export const getTopic = (topicId: string) => {
    return http.get<TopicResponse>(`/topics/${topicId}`).then((res) => res.data.data);
};

export const getHead = (headId: string) => {
    return http.get<HeadResponse>(`/heads/${headId}`).then((res) => res.data.data);
};
