import { Topic, TopicHead } from '../../types/api';

export interface IPageState {
    topic: Topic;
    head: TopicHead;
}

export const initialPageState: IPageState = {
    topic: {
        id: 0,
        name: '',
        heads: [],
        categoryId: 0,
    },
    head: {
        id: 0,
        name: '',
    },
};
