import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { City } from '../../types/api';

const albertRegular = css`
    font-family: 'Albert-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
`;

const RubricsContainer = styled.div`
    ${(props) => css`
        display: flex;
        margin: 15px 0;
        ${props.theme.screen.mobile &&
        css`
            display: block;
        `}
    `}
`;

const BusinessClassTitle = styled.p`
    ${(props) => css`
        font-family: 'Albert-Bold';
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        margin: 0 40px 0 0;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 13px;
            line-height: 16px;
        `}
        ${props.theme.screen.mobile &&
        css`
            margin-bottom: 8px;
        `}
    `}
`;

const Text = styled.p`
    ${(props) => css`
        ${albertRegular}
        display: flex;
        flex-wrap: wrap;
        gap: 4px 0;
        justify-content: flex-start;
        margin: 0 0 17px 0;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 13px;
            line-height: 16px;
        `}
        &.no-margin {
            margin: 0;
            display: flex;
            align-items: center;
        }
    `}
`;

const Rubric = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-right: 16px;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

type Props = {
    cities: City[];
    category: string;
    topic: string;
    head: string;
};

export const RelatedCities = (props: Props) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const navigateToTopicCity = (
        categoryId: string,
        topicId: string,
        headId: string,
        city: City,
        e: React.MouseEvent<HTMLDivElement>
    ) => {
        e.stopPropagation();
        navigate(`/imoniu-sarasas/${categoryId}/${topicId}/${headId}/${city.id}`);
    };

    return (
        <>
            {props.cities && props.cities.length > 0 && (
                <RubricsContainer>
                    <BusinessClassTitle>{t('cities')}:</BusinessClassTitle>
                    <Text className="no-margin">
                        {props.cities.map((city) => (
                            <Rubric
                                onClick={(e) => navigateToTopicCity(props.category, props.topic, props.head, city, e)}
                                key={city.id}
                            >
                                {city.city}
                            </Rubric>
                        ))}
                    </Text>
                </RubricsContainer>
            )}
        </>
    );
};
